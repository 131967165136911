<template>
  <!--
   이전상장 + 벤처기업 + 신구주분리 인 경우 벤처구주 수량에 대해 제한값 'T' 출력
    -->
  <tbody>
    <tr v-if='isListingTransferNSplitRatio'>
      <td class='text-left'>{{result.ipo_summary.subscription_date}}</td>
      <td class='text-left'>{{result.ipo_summary.lockup_expiration_date}}</td>
      <td class='text-left'>{{result.fund_short_name}}</td>
      <td class='text-left'>{{result.strategy}}</td>
      <td class='text-left'>A{{selectedIpo.share_code}}</td>
      <td class='text-left'></td>
      <td class='text-left'>Undefined</td>
      <td class='text-left'>T</td>
      <td class='text-left'>L</td>
      <td class='text-right'>{{numberStyle(result.old_allocated_quantity)}}</td>
      <td class='text-left'>{{currentUserInfo.userName}}</td>
      <td class='text-left'>공모주 {{memo}}</td>
    </tr>
    <tr v-if='isListingTransferNSplitRatio'>
      <td class='text-left'>{{result.ipo_summary.subscription_date}}</td>
      <td class='text-left'>{{result.ipo_summary.lockup_expiration_date}}</td>
      <td class='text-left'>{{result.fund_short_name}}</td>
      <td class='text-left'>Whole</td>
      <td class='text-left'>A{{selectedIpo.share_code}}</td>
      <td class='text-left'></td>
      <td class='text-left'>Cash</td>
      <td class='text-left'>T</td>
      <td class='text-left'>L</td>
      <td class='text-right'>{{numberStyle(result.old_allocated_quantity)}}</td>
      <td class='text-left'>{{currentUserInfo.userName}}</td>
      <td class='text-left'>공모주 {{memo}}</td>
    </tr>
    <tr>
      <td class='text-left'>{{result.ipo_summary.subscription_date}}</td>
      <td class='text-left'>{{result.ipo_summary.lockup_expiration_date}}</td>
      <td class='text-left'>{{result.fund_short_name}}</td>
      <td class='text-left'>{{result.strategy}}</td>
      <td class='text-left'>A{{selectedIpo.share_code}}</td>
      <td class='text-left'></td>
      <td class='text-left'>Undefined</td>
      <td class='text-left'>{{limit}}</td>
      <td class='text-left'>L</td>
      <td class='text-right'>{{numberStyle(allocatedQuantity)}}</td>
      <td class='text-left'>{{currentUserInfo.userName}}</td>
      <td class='text-left'>공모주 {{memo}}</td>
    </tr>
    <tr>
      <td class='text-left'>{{result.ipo_summary.subscription_date}}</td>
      <td class='text-left'>{{result.ipo_summary.lockup_expiration_date}}</td>
      <td class='text-left'>{{result.fund_short_name}}</td>
      <td class='text-left'>Whole</td>
      <td class='text-left'>A{{selectedIpo.share_code}}</td>
      <td class='text-left'></td>
      <td class='text-left'>Cash</td>
      <td class='text-left'>{{limit}}</td>
      <td class='text-left'>L</td>
      <td class='text-right'>{{numberStyle(allocatedQuantity)}}</td>
      <td class='text-left'>{{currentUserInfo.userName}}</td>
      <td class='text-left'>공모주 {{memo}}</td>
    </tr>
  </tbody>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'IpoResultLockupRow',
  props: [
    'result',
  ],
  computed: {
    ...mapState('ipos', [
      'selectedIpo',
    ]),
    ...mapState('users', [
      'currentUserInfo'
    ]),
    isNewShares () {
      return this.result.new_allocated_quantity > 0 ? true : false
    },
    // 확약여부
    isLockUp () {
      return this.result.ipo_summary.is_lock_up
    },
    // 이전상장여부
    isListingTransfer () {
      return this.result.ipo_summary.is_listing_transfer
    },
    isListingTransferNSplitRatio () {
      return this.isListingTransfer && this.result.target_asset === '벤처' && this.result.old_allocated_quantity > 0
    },
    // 제한값
    limit () {
      if (this.isLockUp) {
        return this.isNewShares ? 'PV' : 'P'
      } else if (this.isListingTransfer) {
        return this.isNewShares ? 'TV' : 'T'
      } else {
        return this.isNewShares ? 'V' : 'None'
      }
    },
    allocatedQuantity () {
      if (this.isNewShares) {
        return this.result.new_allocated_quantity
      } else {
        return this.result.old_allocated_quantity
      }
    },
    memo () {
      return this.result.ipo_summary.lock_up_period === '' || this.result.ipo_summary.lock_up_period === null ? '미확약' : this.result.ipo_summary.lock_up_period
    }
  },
  methods: {
    numberStyle (number) {
      return !number ? '' : Number(number).toLocaleString()
    },
  }
}
</script>

<style lang="scss" scoped>
  td {
    @apply tracking-wide sticky top-0 py-3 pl-2 pr-6 border-b whitespace-no-wrap;
  }
</style>
