<template>
  <section v-if='hasIpoResults' class='overflow-x-scroll py-8'>
    <h4>
      <span class='py-2 cursor-pointer' @click='toggleDetails'>
        <component :is='toggleIcon' class='h-4 inline-block inline-block' />
        매매제한기간
      </span>
    </h4>
    <table v-if='showDetails' class='text-sm table-fixed'>
      <thead>
        <tr>
          <th class='text-left'>시작일</th>
          <th class='text-left'>해제일</th>
          <th class='text-left'>펀드</th>
          <th class='text-left'>전략</th>
          <th class='text-left'>종목코드</th>
          <th class='text-left'>종목명</th>
          <th class='text-left'>북</th>
          <th class='text-left'>제한</th>
          <th class='text-left'>LS</th>
          <th class='text-right'>수량</th>
          <th class='text-left'>입력자</th>
          <th class='text-left'>메모</th>
        </tr>
      </thead>
        <ipo-result-lockup-row  v-for='result in filteredResults' :key='`${result.target_asset}-${result.fund_code}-${result.fund_short_name}`' :result='result' />
    </table>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import { ChevronDownIcon, ChevronRightIcon } from '@vue-hero-icons/outline'
import IpoResultLockupRow from '@/views/ipo/IpoResultLockupRow.vue'

export default {
  name: 'IpoResultLockup',
  components: {
    IpoResultLockupRow,
    ChevronDownIcon,
    ChevronRightIcon,
  },
  data () {
    return {
      showDetails: false,
    }
  },
  computed: {
    ...mapState('ipos', [
      'ipoResults',
    ]),
    toggleIcon () {
      return this.showDetails ? 'chevron-down-icon' : 'chevron-right-icon'
    },
    filteredResults () {
      return this.ipoResults.filter(result => (result.ipo_summary.is_lock_up || result.ipo_summary.is_listing_transfer || result.new_allocated_quantity) && result.total_allocated_quantity > 0)
    },
    hasIpoResults () {
      return this.filteredResults.length > 0
    },
  },
  methods: {
    toggleDetails () {
      this.showDetails = !this.showDetails
    },
    numberStyle (number) {
      return !number ? '' : Number(number).toLocaleString()
    },
  }
}
</script>

<style lang='scss' scoped>
  th {
    @apply uppercase tracking-wide sticky top-0 py-3 pl-2 pr-6 border-b whitespace-no-wrap;
  }
</style>
